<template>
  <module-list-layout :createAction="createAction" :module="module" v-if="amReady">
    <!--    <img src="http://127.6.1.2:8000/xapi/Storage/File/consume/WxCi">-->
    <el-dialog :visible.sync="dialogVisible" custom-class="el-custom-dialog" title="" top="5vh" width="75%">
      <moduleForm
          :autoBind="false"
          :bindingForView="true"
          :dataToBind="dataToBind"
          :edit-mode="editMode"
          :formHeader="true"
          :formTitle="((editMode)? 'Update ' : 'Create ') + module.info.caption"
          :moduleDeleteAction='moduleFormDeleteAction'
          :on-create-callback="afterCreate"
          :on-edit-callback="afterEdit"
          v-if="dialogVisible"></moduleForm>
    </el-dialog>

    <div class="row">
      <div class="col-12 col-md-6 px-4">
        <dg-text-field
            :field_span="24"
            :label_span="0"
            :liveUpdate="true"
            :translateLabel="false"
            @amUpdated="userSearchUpdated"
            class="mt-1"
            field_mark=""
            label=""
            placeholder="search here"
            style="max-width: 400px"></dg-text-field>
      </div>
      <div class="col-12 col-md-6 float-right text-right">
         <span style="display: inline-block;margin-right: 20px;">
               <small> records: {{ recordsCounter }} | {{ pageChunkSize }} record per page</small>
        </span>
        <pagination
            :currentPage="currentPage"
            :margin-pages="2"
            :navigateHandler="navigateToPage"
            :page-count="Math.ceil(recordsCounter / pageChunkSize)"
            :page-range="4"
            class="mt-1 d-inline-block"
            paginateClass=""
            style="margin-right: 20px;"></pagination>

        <el-button @click="removeSelectedAction" icon="el-icon-delete" size="mini" type="danger">Selected</el-button>
      </div>
    </div>

    <el-table :data="tableData" @selection-change="handleTableSelectionChange" border cell-class-name="p-1" stripe
              style="width: 100%">
      <el-table-column
          type="selection"
          width="55">
      </el-table-column>
      <el-table-column type="expand">
        <template slot-scope="props">
          <el-table
              :data="[props.row]"
              border
              cell-class-name="p-1"
              header-cell-class-name="app-light-silver-background p-1"
              style="width: 100%;">
            <el-table-column
                label="Vendor">
              <template slot-scope="scope">
                <popover :content="'Prefix: ' + scope.row.name" :data="scope.row.name" title=""></popover>
              </template>
            </el-table-column>
          </el-table>
        </template>
      </el-table-column>
      <el-table-column
          label="Name"
          prop="name"
          sortable
          width="250">
        <template slot-scope="scope">
          <smartInput :customParams="{data:scope.row}" :inputValue="scope.row.name"
                      :noCopy="true"
                      :saveAction="updateField" editContainerStyle="top: 0; position: relative;" fieldName='name'
                      inputWidth='170px'></smartInput>
        </template>
      </el-table-column>
      <el-table-column
          label="Numbers"
          width="250">
        <template slot-scope="scope">
          <popover :content="scope.row.name" data="" title="Dialer Numbers">
            <template v-slot:body>
              <smartInput :customParams="{data:scope.row}" :inputValue="scope.row.name"
                          :noCopy="true"
                          :saveAction="updateField" editContainerStyle="top: 0; position: relative;" fieldName='name'
                          inputWidth='170px'></smartInput>
            </template>
          </popover>
        </template>
      </el-table-column>
      <el-table-column
          label="Created"
          prop="created_at"
          sortable
          width="200">
      </el-table-column>
      <el-table-column
          label="Status"
          width="200">
        <template slot-scope="scope">
          <vSwitch :customData="{data:scope.row}" :saveAction="updateField" :value="scope.row.active"
                   fieldName="active"></vSwitch>
        </template>
      </el-table-column>
      <el-table-column
          label="Download"
          width="200">
        <template slot-scope="scope">
          <el-button size="small" :a="{data:scope.row}" @click="$store.dispatch('App-App-state/downloadFile', {url: scope.row.download_link, fileName: 'tmtm'})" icon="el-icon-download">Download</el-button>
        </template>
      </el-table-column>
      <el-table-column
          align="right"
          label="Actions"
          prop="">
        <template slot-scope="scope">
          <vDropdown :actionsHandler="actionsHandler" :items="usersActions"
                     :params="{data: scope.row, rowIndex: scope.$index + 1} "
                     :userProfileId="$store.state['App-User-state'].profile.id"></vDropdown>
        </template>
      </el-table-column>
    </el-table>

    <el-dialog :append-to-body="true" :visible.sync="deleteSelectedDialog" center title="Warning" width="420px">
      <div align="center" class="mt-2 mb-2">Are you sure you want to remove all records ?</div>
      <span class="dialog-footer" slot="footer">
        <el-button @click="deleteSelectedDialog = false" size="small">Cancel</el-button>
        <el-button @click="removeSelectedApi" size="small" type="danger">Yes</el-button>
      </span>
    </el-dialog>

    <el-dialog :visible.sync="deleteDialogVisible" center title="Warning" width="420px">
      <div align="center" class="mt-2 mb-2">You want to remove this record ?</div>
      <span class="dialog-footer" slot="footer">
        <el-button @click="deleteDialogVisible = false" size="small">Cancel</el-button>
        <el-button @click="removeActionApi" size="small" type="danger">Yes</el-button>
      </span>
    </el-dialog>
  </module-list-layout>
</template>

<script>
import module from './../../index'

export default {
  mixins: [$mixins['listView']],
  components: {moduleForm: () => import('../../form/View')},
  methods: {
    ...$mapActions(module.info.fullName + '-api', {
      getEntityList: 'list',
      updateEntity: 'update',
      multipleDelete: "multipleDelete"
    }),
  },
  computed: {
    ...$mapState(module.info.fullName + '-api', ['pageChunkSize']),
  },
  data() {
    return {
      module: {...module},
      usersActions: [
        {
          label: ' View / Edit',
          icon: 'el-icon-edit-outline',
          action: 'editAction',
          notForProfiles: []
        },
        {
          label: 'Remove',
          icon: 'el-icon-delete',
          action: 'removeAction',
        },
      ]
    };
  }
}
</script>
